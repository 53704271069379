import React from "react";
import { FaLinkedinIn, FaEnvelope, FaGithub } from "react-icons/fa";

const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/in/hanzi-jiang-jhz/"
  },
  {
    Social: <FaGithub />,
    link: "https://www.github.com/hanzijiang"
  },
  { Social: <FaEnvelope />, link: "mailto:hanzijiang18@gmail.com" }
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center text-sm-center justify-content-md-end">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
