import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row justify-content-center section-title text-center">
        <div className="col-lg-7">
          <h3 className="font-alt">More About Me</h3>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Education</h4>
          </div>
          <ul className="aducation-box gray-bg">
            <li>
              <span className="dark-bg">2018.09-2023.05</span>
              <h6>University of Toronto</h6>
              <p>Honours BSc in Computer Science</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Dean's List Student (GPA 3.88/4.0)
              </p>
              <br></br>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                My favourite courses:{" "}
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://www.eecg.toronto.edu/~yuan/teaching/ece454/">
                  Computer Systems Programming
                </a>
                ,
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://www.ece.utoronto.ca/wp-content/uploads/2019/05/syllabus419.pdf/">
                  Distributed Systems
                </a>
                ,
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://artsci.calendar.utoronto.ca/course/csc367h1/">
                  Parallel Programming
                </a>
                ,
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://uoft-csc413.github.io/2022/">
                  Neural Networks and Deep Learning
                </a>
                ,
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://www.cs.toronto.edu/~frank/csc401/">
                  Natural Language Computing
                </a>
                ,
              </p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                <a href="https://www.cs.utoronto.ca/~fidler/teaching/2021/CSC420.html/">
                  Image Understanding
                </a>
              </p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Work Experience</h4>
          </div>
          <ul className="aducation-box gray-bg">
            <li>
              <span className="dark-bg">2022.05-2022.08</span>
              <h6>Software Engineer Intern</h6>
              <p>Momentive.ai (formerly SurveyMonkey)</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Designed and developed an email blocklist functionality to check
                12,000+ sign-up requests per day in production to prevent
                platform abuse.
              </p>
            </li>

            <li>
              <span className="dark-bg">2021.05-2022.05</span>
              <h6>AR Application Developer</h6>
              <p>ModiFace</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Developed and maintained cross-platform C++/OpenGL and Android
                rendering engines for virtual makeover experiences.
              </p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Research Experience</h4>
          </div>
          <ul className="aducation-box gray-bg">
            <li>
              <span className="dark-bg">2023.01-2023.05</span>
              <h6>ML Systems Researcher</h6>
              <p>ParaMathics</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Systems for machine learning and GPU programming.
              </p>
            </li>

            <li>
              <span className="dark-bg">2021.12-2022.05</span>
              <h6>ML/CV Researcher (volunteer)</h6>
              <p>Medical Computer Vision and Robotics Lab</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Surgical needle pose estimation for robot-assisted surgery.
              </p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        {/* <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Volunteer Experience</h4>
          </div>
          <ul className="aducation-box gray-bg">
            <li>
              <span className="dark-bg">2020.09-2021.01</span>
              <h6>Leader of Recognized Study Group</h6>
              <p>University of Toronto</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Led a Recognized Study Group and held bi-weekly meetings to
                discuss machine learning materials.
              </p>
            </li>

            <li>
              <span className="dark-bg">2018.09-2019.09</span>
              <h6>Blue Crew Member</h6>
              <p>University of Toronto Students'​ Union</p>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                Actively participated in the community by volunteering at
                various events.
              </p>
            </li>
          </ul>
        </div>

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Certificates</h4>
          </div>
          <ul className="aducation-box gray-bg">
            <li>
              <span className="dark-bg">2021.09-2021.10</span>
              <h6>Analyze Datasets and Train ML Models using AutoML</h6>
              <p style={{ fontSize: 16 + "px", color: "Gray" }}>
                An online non-credit course authorized by DeepLearning.AI, AWS,
                and offered through Coursera.
              </p>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default Skills;
