import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const AllPortfolioContent = [
  {
    img: "report-demo",
    title:
      "A Comparative Analysis of CapsNet and ResNet on Rotation Invariance",
    subTitle: "Python, PyTorch, Deep Learning",
    delayAnimation: "200",
    portfolioLink:
      "https://github.com/HanziJiang/CapsNet-ResNet-Performance-Analysis",
    descriptions:
      "Designed and conducted experiments to investigate the rotation invariance of Capsule Neural Network and Residual Neural Network."
  },
  {
    img: "unity-demo",
    title: "Decorate It!",
    subTitle: "C#, Unity, Android",
    delayAnimation: "200",
    portfolioLink: "https://github.com/HanziJiang/Christmas-Tree-AR-Unity",
    descriptions:
      "An Unity AR game (hackathon project) that allows the user to place a Christmas Tree in their scene and decorate it with gifts, ornaments and lights."
  },
  {
    img: "puzzle-demo",
    title: "Puzzle Mazing",
    subTitle: "Java, Flux, Android",
    delayAnimation: "200",
    portfolioLink: "https://github.com/HanziJiang/Puzzle-Mazing",
    descriptions:
      "Developed a multi-level Android game from scratch in a team of five using the Flux architecture."
  },
  {
    img: "doodle-demo",
    title: "Doodle Jump Assembly",
    subTitle: "MIPS Assembly Language",
    delayAnimation: "200",
    portfolioLink: "https://github.com/HanziJiang/Doodle-Jump-Assembly",
    descriptions:
      "Doodle Jump in Assembly with increasing difficulty. Doodle loves rocket and trampoline time."
  }
];

const PortfolioAnimation = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <SRLWrapper>
            <div className="portfolio-content row lightbox-gallery">
              {AllPortfolioContent.map((val, i) => (
                <div
                  className="col-sm-6 col-lg-4 grid-item product"
                  key={i}
                  data-aos="fade-right"
                  data-aos-duration="1200"
                  data-aos-delay={val.delayAnimation}
                >
                  <div className="portfolio-box-01">
                    <div className="portfolio-img">
                      <div className="portfolio-info">
                        <h5>
                          <a href={val.portfolioLink} target="_blank">
                            {val.title}
                          </a>
                        </h5>
                        <span>{val.subTitle}</span>
                        <p>{val.descriptions}</p>
                      </div>
                      {/* End .portfolio-info */}
                      <a
                        href={`img/portfolio/${val.img}.jpg`}
                        className="gallery-link"
                      >
                        <img
                          src={`img/portfolio/${val.img}.jpg`}
                          alt={val.alterText}
                        />
                      </a>
                      {/* End gallery link */}
                      <a
                        href={val.portfolioLink}
                        className="portfolio-icon"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="ti-link"></span>
                      </a>
                      {/* End .portfolio-icon */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* End list wrapper */}
          </SRLWrapper>
          {/* End tabpanel */}
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default PortfolioAnimation;
