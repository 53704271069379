import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-5 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/head.jpg"/>
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Hanzi Jiang</h3>
            <p>
              As a final-year computer science student at the University of Toronto, I have a proven ability to design, develop, and deliver maintainable and high-performing code. My area of expertise and interest lies in backend development and performance optimization. I am constantly seeking to expand my skills by exploring emerging fields within the industry. 
            </p>
            {/* End .row */}
            {/* <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/resume.pdf" download>
                Download Resume
              </a>
            </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
